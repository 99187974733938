:root {
  --padding-horizontal: 5%;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .header h1 {
    font-size: 2.2rem;
  }

  .header h2 {
    font-size: 1.2rem;
  }

  .cta {
    padding: 30px 15px;
  }

  .cta h3 {
    font-size: 1.5rem;
  }

  .cta-button {
    font-size: 1rem;
  }
}

body {
  font-family: 'Lato', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
  color: #333;
}

.app-container {
  padding: 0;
}

.header {
  text-align: center;
  padding: 10px 20px;
  background: linear-gradient(135deg, rgba(106, 17, 203, 0.8) 0%, rgba(37, 117, 252, 0.8) 100%),
              url('./assets/images/hero.png') center/cover;
  background-blend-mode: overlay;
  color: #f5f5f5;
  position: relative;
  display: grid;
  grid-template-rows: repeat(2, max-content);
  gap: 40px;
  z-index: 2;
}

/* Overlay for contrast */
.header::before {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

/* Text styles */
.header h1,
.header h2 {
  position: relative;
  z-index: 2;
  color: #f8f8ff;
  text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.8);
}

.header h1 {
  font-size: 3rem;
  margin-bottom: 10px;
  font-weight: 700;
  letter-spacing: 1px;
}

.header h2 {
  font-size: 1.5rem;
  font-weight: 300;
  margin: 0;
  text-align: center;
}

/* CTA Section */
.cta {
  text-align: center;
  padding: 50px var(--padding-horizontal);
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.cta h3 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #2c3e50;
}

.cta p {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 30px;
  text-align: left;
}

.cta:hover {
  transform: translateY(-5px);
}

/* CTA Button */
.cta-button {
  padding: 15px 30px;
  background: linear-gradient(135deg, #f093fb 0%, #f5576c 100%);
  color: #fff;
  font-size: 1.1rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
  border-radius: 25px;
  transition: background 0.3s, transform 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cta-button:hover {
  background: linear-gradient(135deg, #f5576c 0%, #f093fb 100%);
  transform: translateY(-2px);
}

/* About Section */
.about {
  padding: 0px var(--padding-horizontal);
}

.professional-statement {
  padding: 0px var(--padding-horizontal);
  padding-top:  0;
}

.professional-statement-resume {
  text-align: center;
}

.services {
  padding: 50px var(--padding-horizontal);
  background-color: #fff;
}

/* Modal */
.modal {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.modal-main {
  position: relative;
  background: #fff;
  padding: 40px;
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.5s ease;
}

.display-block {
  display: flex;
}

.display-none {
  display: none;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
}

.modal-close:hover {
  color: #000;
}

/* Contact Form */
.contact-form {
  padding: 50px var(--padding-horizontal);
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.contact-form form {
  display: flex;
  flex-direction: column;
}

.contact-form label {
  margin-bottom: 10px;
  font-weight: 600;
  color: #333;
}

.contact-form input,
.contact-form textarea,
.contact-form select {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.cta-button {
  padding: 15px 30px;
  background: linear-gradient(135deg, #f093fb 0%, #f5576c 100%);
  color: #fff;
  font-size: 1.1rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
  border-radius: 25px;
  transition: background 0.3s, transform 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: inline-block;
  white-space: nowrap;
}

.cta-button:hover {
  background: linear-gradient(135deg, #f5576c 0%, #f093fb 100%);
  transform: translateY(-2px);
}


/* Portfolio Section */
.portfolio-container {
  padding: 0px var(--padding-horizontal);
  text-align: left;
}
.portfolio-container h3 {
  text-align: left;
}

.project-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 3rem;
  justify-content: center;
  max-width: calc(4 * 280px + 3 * 3rem);
  margin: 0 auto;
}

.project-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #ddd;
  width: 100%;
  max-width: 400px;
  border-radius: 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background: linear-gradient(135deg, #f3f4f7, #e1e2e5);
  transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out;
  overflow: hidden;
  padding-bottom: 1rem;
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
}

.project-card img {
  vertical-align: top;
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 200px;
  object-fit: contain;
  border-radius: 10px, 10px, 0, 0;
  margin-bottom: 10px;
  margin-top: 0;
  padding: 0;
}

.project-info {
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  height: calc(1.6em * 5);
  margin: 0 1rem;
}

.project-info h3 {
  font-size: 1.2rem;
  color: #333;
}

.expand-btn {
  padding: 10px 20px;
  background-color: #FFB84C;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 0.85rem;
  margin-top: 10px;
  margin-bottom: 1rem;
  transition: background-color 0.4s ease-in-out, transform 0.4s ease-in-out;
  display: inline-block;
}

.project-card .expand-btn {
  align-self: center;
  transform: translateY(0);
}

.project-card.expanded .expand-btn {
  margin-top: auto;
  transform: translateY(20px);
}

.expand-btn:hover {
  background-color: #D98F2B;
}

/*  project-details transition with scale effect */
.project-details {
  margin-top: 15px;
  text-align: left;
  font-size: 0.85rem;
  color: #444;
  max-height: 0;
  opacity: 0;
  transform-origin: top;
  transform: scaleY(0);
  transition: max-height 0.4s ease-in-out, opacity 0.4s ease-in-out, padding 0.4s ease-in-out, transform 0.4s ease-in-out;
  padding: 0;
  overflow: hidden;
  margin: 0 1rem;
}

.project-card.expanded .project-details {
  max-height: 1000px;
  opacity: 1;
  transform: scaleY(1);
  padding: 15px 0;
}

.project-images,
.project-videos {
  margin-top: 10px;
}

.project-images img,
.project-videos video {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  border-radius: 8px;
}

.show-all-btn {
  padding: 12px 25px;
  background-color: #FF9155;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 20px;
  transition: background-color 0.4s ease-in-out;
  display: block;
  margin-left: auto;
  margin-right: auto;
  
}

.show-all-btn:hover {
  background-color: #FF7733;
}
